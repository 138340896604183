export function assert(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	condition: any,
	message: string,
): asserts condition {
	if (condition) {
		return;
	}
	throw new Error(`Assert failed: ${message}`);
}
